<template>
  <div>
    <div class="app-header">
      <img :alt="$config.client.name" :src="logoPath"/>
    </div>
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
    <div class="center">
      <b-container class="msg">
        <p>
          Une erreur est survenue lors de la déconnexion.<br>
        </p>
        <b-button type="button" variant="outline-primary" data-test="signOutFailed-retry" @click.prevent="retrySignout">
          {{ $t('words.retry') }}
        </b-button>
      </b-container>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SignOutFailed',

  data: () => {
    return {
      loading: false
    };
  },

  computed: {
    ...mapState('sign-out', [
      'logged',
      'error'
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  methods: {
    ...mapActions('sign-out', [
      'GET_SIGNOUT'
    ]),

    retrySignout() {
      this.loading = false;
      this.GET_SIGNOUT().then(() => {
        this.loading = false;
        if (!this.logged) {
          this.$router.push({ name: 'SignOut' });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.center {
  position: initial;
}

.center .msg.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center .msg.container,
.center .msg.container button {
  font-size: large;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
